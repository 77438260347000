@import "src/styles/_variables-mixins.scss";
.DialogOverlay {
  position: fixed;
  inset: 0;
  z-index: 598;

  background-color: rgba(0, 0, 0, 0.6);
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}
.DialogWrapper {
  position: fixed;
  inset: 0;
  z-index: 599;
  overflow-y: scroll;

  display: flex;
  align-items: flex-start;
  justify-content: center;

  padding: 10vh 0;

  @include mediaQuery($md) {
    &--mobile-full {
      padding: 0;
    }
  }
}

.DialogContent {
  position: relative;
  // top: 50%;
  // left: 50%;
  z-index: 600;

  width: 90vw;
  max-width: 1200px;
  padding: 40px;

  border-radius: 24px;
  background-color: var(--mobility-color-light-50);
  box-shadow:
    hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  // transform: translate(-50%, -50%);
  // animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);

  &:focus {
    outline: none;
  }

  @include mediaQuery($md) {
    padding: 26px;

    &--mobile-full {
      width: 100%;
      max-width: 100%;

      border-radius: 0;
    }
  }
}

.IconButton {
  position: absolute;
  top: 20px;
  right: 20px;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 32px;
  height: 32px;

  border-radius: 100%;
  color: var(--mobility-color-dark-950);
  background-color: transparent;
  border: 0;
  cursor: pointer;

  &:hover {
    background-color: var(--mobility-color-orange-50);
  }
  &:focus {
    box-shadow: 0 0 0 2px var(--mobility-color-orange-50);
  }
}

@keyframes overlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes contentShow {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}
