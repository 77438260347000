$fontSizeBase: 16px;

// `sm` applies to x-small devices (portrait phones, less than 576px)
$sm: 576px;
// `md` applies to small devices (landscape phones, less than 768px)
$md: 768px;
// `lg` applies to medium devices (tablets, less than 992px)
$lg: 992px;
// `xl` applies to large devices (desktops, less than 1200px)
$xl: 1200px;
// `xxl` applies to x-large devices (large desktops, less than 1400px)
$xxl: 1400px;

@mixin mediaQuery($breakpoint, $mediaQuery: 'max-width') {
  @media (#{$mediaQuery}: #{$breakpoint}) {
    @content;
  }
}

@mixin responsiveFontSize($default, $large, $medium) {
  font-size: $default;

  @include mediaQuery($xl) {
    font-size: $large;
  }

  @include mediaQuery($md) {
    font-size: $medium;
  }
}

@mixin responsiveFontWeight($default, $medium, $small) {
  font-weight: $default;

  @include mediaQuery($lg) {
    font-weight: $medium;
  }

  @include mediaQuery($md) {
    font-weight: $small;
  }
}

@mixin responsiveLineHeight($default, $large, $medium) {
  line-height: $default;

  @include mediaQuery($xl) {
    line-height: $large;
  }

  @include mediaQuery($md) {
    line-height: $medium;
  }
}

@function calcFontSize($pixelSize, $baseUnit: $fontSizeBase) {
  @return ($pixelSize / $baseUnit) + rem;
}
