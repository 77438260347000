@import "src/styles/_variables-mixins.scss";
.login {
  display: flex;
  flex-direction: column;
  align-self: center;

  width: 100%;
  margin: auto;

  :global(.title) {
    text-align: center;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 40px;

    width: 100%;
    margin-top: 40px;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    gap: 24px;

    width: 100%;
  }

  &__recaptcha {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & p {
      font-size: 14px;
      font-weight: 500;
      color: #ce2e2e;
    }
  }
}
